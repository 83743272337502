<template>
    <v-container class="pa-0" fluid>
        <v-navbar />
        <v-row align="center" justify="center" no-gutters>
            <router-view />
        </v-row>
    </v-container>
</template>
<script>
import VNavbar from "../components/Navbar.vue";

export default {
    components: {
        VNavbar
    }
};
</script>
